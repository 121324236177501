import gql from 'graphql-tag';

import assetFragment from './asset-fragment';
import ctaFragment from './cta-fragment';

const contentHeroFragment = gql`
  fragment contentHeroFields on ContentHero {
    title
    text
    image {
      ...assetFields
      url(width: 1920, format: "webp")
    }
    focusPoint: focus_point
    cta {
      ...ctaFields
    }
    secondCta: second_cta {
      ...ctaFields
    }
  }
  ${assetFragment}
  ${ctaFragment}
`;

export default contentHeroFragment;
