import VacancyCounter from '@atoms/VacancyCounter/VacancyCounter';
import useTranslations from '@hooks/useTranslations';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import Section from '@molecules/Section/Section';
import SectionContent from '@molecules/Section/SectionContent';
import ContentHero from '@organisms/ContentHero/ContentHero';
import Sections from '@organisms/Sections/Sections';
import VacancyGrid from '@organisms/VacancyGrid/VacancyGrid';
import type { VacanciesOverviewPage as Props } from '@type-declarations/page';

export default function VacanciesOverviewTemplate({ page }: { page: Props }) {
  const { title, hero, items, total } = page;
  const t = useTranslations();

  return (
    <>
      <Breadcrumbs page={page} />

      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
        secondCta={hero?.secondCta}
      />

      <Section>
        <VacancyCounter total={total} />

        {items.length ? (
          <VacancyGrid vacancies={items} />
        ) : (
          <SectionContent
            title={t.noVacanciesFoundTitle}
            text={t.noVacanciesFoundText}
            fullWidth
          />
        )}
      </Section>

      <Sections sections={page.sections} />
    </>
  );
}
