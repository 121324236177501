import useTranslations from '@hooks/useTranslations';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import CompaniesGrid from '@organisms/CompaniesGrid/CompaniesGrid';
import ContentHero from '@organisms/ContentHero/ContentHero';
import Sections from '@organisms/Sections/Sections';
import TextSection from '@organisms/TextSection/TextSection';
import type { CompaniesOverviewPage as Props } from '@type-declarations/page';

export default function CompaniesOverviewTemplate({ page }: { page: Props }) {
  const {
    title,
    hero,
    items,
    lastTicketTitle,
    lastTicketImage,
    lastTicketCta,
  } = page;
  const t = useTranslations();

  return (
    <>
      <Breadcrumbs page={page} />

      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
        secondCta={hero?.secondCta}
      />

      {!items.length && (
        <TextSection
          id="not-found-message"
          typename="TextSection"
          title={t.nothingFoundTitle}
          text={t.nothingFoundText}
          fullWidth
        />
      )}

      {items.length > 0 && (
        <CompaniesGrid
          items={items}
          lastTicketTitle={lastTicketTitle}
          lastTicketImage={lastTicketImage}
          lastTicketCta={lastTicketCta}
        />
      )}

      <Sections sections={page.sections} />
    </>
  );
}
