import { useOverview } from '@hooks/useOverview';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import { fetchNewsPages } from '@lib/prepr/news';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import CardGrid from '@organisms/CardGrid/CardGrid';
import ContentHero from '@organisms/ContentHero/ContentHero';
import Sections from '@organisms/Sections/Sections';
import TagsFilter from '@organisms/TagsFilter/TagsFilter';
import type { NewsOverviewPage as Props } from '@type-declarations/page';
import { isAnchrDomain } from '@utils/getDomain';

export default function NewsOverviewTemplate({ page }: { page: Props }) {
  const { title, hero, items: initItems, total: initTotal, tags } = page;
  const t = useTranslations();
  const {
    store: { host },
  } = useStore();
  const {
    items,
    total,
    hasNextPage,
    handleLoadMore,
    onFilterChange,
    activeTag,
    loading,
  } = useOverview({
    initItems,
    initTotal,
    fetch: fetchNewsPages,
  });
  let generalNewsLabel: string = t.tagsFilter.generalNews;
  if (isAnchrDomain(host)) {
    generalNewsLabel = t.tagsFilter.generalNewsAnchr;
  }

  return (
    <>
      <Breadcrumbs page={page} />

      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
        secondCta={hero?.secondCta}
      />

      <TagsFilter
        tags={tags}
        activeTag={activeTag}
        total={total}
        onChange={onFilterChange}
        generalFilterLabel={generalNewsLabel}
      />

      <CardGrid
        loading={loading}
        items={items}
        hasNextPage={hasNextPage}
        handleLoadMore={handleLoadMore}
        notFoundTitle={t.noNewsFoundTitle}
        notFoundText={t.noNewsFoundText}
      />

      <Sections sections={page.sections} />
    </>
  );
}
