import { isBridgDomain, isNimblDomain } from '@utils/getDomain';

export function getEnvironmentUrl(host: string) {
  switch (true) {
    case isNimblDomain(host):
      return process.env.ENVIRONMENT_URL_NIMBL;
    case isBridgDomain(host):
      return process.env.ENVIRONMENT_URL_BRIDG;
    default:
      return process.env.ENVIRONMENT_URL_ANCHR;
  }
}
