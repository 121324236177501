import { useOverview } from '@hooks/useOverview';
import useTranslations from '@hooks/useTranslations';
import { fetchCasePages } from '@lib/prepr/cases';
import Breadcrumbs from '@molecules/Breadcrumbs/Breadcrumbs';
import CardGrid from '@organisms/CardGrid/CardGrid';
import ContentHero from '@organisms/ContentHero/ContentHero';
import Sections from '@organisms/Sections/Sections';
import TagsFilter from '@organisms/TagsFilter/TagsFilter';
import type { CasesOverviewPage as Props } from '@type-declarations/page';

export default function CasesOverviewTemplate({ page }: { page: Props }) {
  const { title, hero, items: initItems, total: initTotal, tags } = page;
  const t = useTranslations();
  const {
    items,
    total,
    hasNextPage,
    handleLoadMore,
    onFilterChange,
    activeTag,
    loading,
  } = useOverview({
    initItems,
    initTotal,
    fetch: fetchCasePages,
  });

  return (
    <>
      <Breadcrumbs page={page} />

      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
        cta={hero?.cta}
        secondCta={hero?.secondCta}
      />

      <TagsFilter
        tags={tags}
        activeTag={activeTag}
        total={total}
        onChange={onFilterChange}
      />

      <CardGrid
        loading={loading}
        items={items}
        hasNextPage={hasNextPage}
        handleLoadMore={handleLoadMore}
        notFoundTitle={t.noCasesFoundTitle}
        notFoundText={t.noCasesFoundText}
      />

      <Sections sections={page.sections} />
    </>
  );
}
