import Icon from '@atoms/Icon/Icon';
import Link from '@atoms/Link/Link';
import StructuredData from '@atoms/StructuredData/StructuredData';
import useBreadcrumbs, { Breadcrumb } from '@hooks/useBreadcrumbs';
import useStore from '@hooks/useStore';
import useTranslations from '@hooks/useTranslations';
import { Page } from '@type-declarations/page';
import { getEnvironmentUrl } from '@utils/getEnvironmentUrl';
import clsx from 'clsx';
import { BreadcrumbList } from 'schema-dts';

import styles from './Breadcrumbs.module.scss';

function getSchema(breadcrumbs: Breadcrumb[], host: string): BreadcrumbList {
  return {
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map((breadcrumb: Breadcrumb, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: breadcrumb.title,
      item: `${getEnvironmentUrl(host)}${breadcrumb.slug.replace('/', '')}`,
    })),
  };
}

export default function Breadcrumbs({ page }: { page: Page }) {
  const {
    store: { host },
  } = useStore();

  const t = useTranslations();
  const { breadcrumbs } = useBreadcrumbs(page);

  if (!breadcrumbs?.length) return null;

  return (
    <div
      className={clsx(
        styles.breadcrumbs,
        'u-container-width u-typography-paragraph-xs',
      )}
    >
      <nav aria-label="Breadcrumb">
        <ol className={clsx(styles.list, 'u-list-clean u-mb-0')}>
          {breadcrumbs.map(({ title, slug }, index) => {
            const secondToLastPage = breadcrumbs.length - 1 === index + 1;
            const currentPage = breadcrumbs.length === index + 1;
            return (
              <li
                key={slug}
                className={clsx(
                  styles.listItem,
                  secondToLastPage && styles.secondToLastPage,
                )}
              >
                {secondToLastPage && (
                  <Icon
                    icon="arrow-left"
                    className={styles.arrowLeftIcon}
                    aria-hidden
                  />
                )}
                <Link
                  href={slug}
                  className={clsx(
                    styles.link,
                    currentPage && styles.currentPage,
                  )}
                  aria-current={currentPage ? 'page' : null}
                >
                  {secondToLastPage && (
                    <span className={styles.backToLabel} aria-hidden>
                      {t.breadcrumbs.backTo}{' '}
                    </span>
                  )}
                  <span>{title}</span>
                </Link>
                {!currentPage && (
                  <span className={styles.seperator} aria-hidden>
                    /
                  </span>
                )}
              </li>
            );
          })}
        </ol>
      </nav>
      <StructuredData schema={getSchema(breadcrumbs, host)} />
    </div>
  );
}
